.iconmenu {    
    color: #008080;    
}

.iconmenu:hover{
    color: #035353;
}

.maintext {
    color: #008080;
    font-weight: bold;
}

.maintext:hover {
    color: #035353;
}

.NavdropActive {
    display: block;
}

.NavdropInActive {
    display: none;
}

.mainlink {
    color :#864e4e     
}

.mainlink:hover {
    color :#52150afe  
}

.mainbutton {
    background-color: #008080;
    outline-color: #52150afe;
}

.mainbutton:hover {
    background-color: #035353;
}
